document.addEventListener("DOMContentLoaded", (event) => {
    const { redirectUser, setMarketplaceLink, applyCustomUi } = Routing;

    redirectUser();
    setMarketplaceLink();
    applyCustomUi();
});

const Routing = (function () {
    const pathName = getPathName();
    const userTraits = getFromLocalStorage("ajs_user_traits");
    const userId = getFromLocalStorage("ajs_user_id");

    function redirectUser() {
        if (!isPathInRoutingConfig()) return;

        const redirectLink = getRedirectLink();

        if (redirectLink === pathName || redirectLink.includes(window.location.href)) return;

        window.location.href = redirectLink;
    }

    function getUserType() {
        for (let key in ACTIVE_USER_MIGRATION_LIST) {
            if (ACTIVE_USER_MIGRATION_LIST[key].includes(userId)) return singularize(key);
        }
        
        if (
            (userId === null || isObjectEmpty(userId)) &&
            ((isObjectEmpty(userTraits) || (userTraits && !("email" in userTraits))))
        ) {
            return "new_user";
        }

        return "existing_user";
    }

    function setMarketplaceLink() {
        const marketplaceForms = document.querySelectorAll(".marketplace-form, .footer-cta-form");

        if (!marketplaceForms.length) return;

        const userType = getUserType();
        const siteOrigin = getUserOrigin();
        const { marketplaceLink, ctaBtnLabel } = getMarketplace(userType, siteOrigin);
        
        marketplaceForms.forEach((form) => {
            if (form.action !== marketplaceLink) {
                form.action = marketplaceLink;
            }

            if (ctaBtnLabel) {
                const submitBtn = form.querySelector('input[type="submit"]');

                if (submitBtn && submitBtn.value !== ctaBtnLabel) {
                    submitBtn.value = ctaBtnLabel;
                }
            }
        });
    }

    function applyCustomUi() {
        const userType = getUserType();
        const siteOrigin = getUserOrigin();
        const customUi = RoutingConfig.all?.[userType]?.ui;

        if (!customUi) return;

        if (Array.isArray(customUi)) {
            modifyUi(customUi);
        } else {
            ["all", siteOrigin].forEach((key) => {
                if (customUi[key]) {
                    modifyUi(customUi[key]);
                }
            });
        }
    }

    function modifyUi(customUi) {
        customUi.forEach((ui) => {
            const targetElement = document.querySelector(ui.target);

            if (!targetElement) return;

            if (ui.href) {
                targetElement.href = ui.href;
            } else if (ui.style) {
                targetElement.style.cssText = ui.style;
            }
        });
    }

    function getUserOrigin() {
        return userTraits?.feedr_user ? "feedr" : "eatfirst";
    }


    function getRedirectLink() {
        const siteOrigin = getUserOrigin();
        const userType = getUserType();
        const redirect = RoutingConfig[pathName]?.[userType]?.redirect;

        return redirect?.links?.[siteOrigin] || redirect || defaultRedirectLink;
    }

    function getMarketplace(userType, siteOrigin) {
        const marketplace = RoutingConfig.all?.[userType]?.marketplace;
        let marketplaceLink = feedrMarketplaceLink;
        let ctaBtnLabel = null;

        if ("link" in marketplace){
            marketplaceLink = marketplace.link;
        }

        if ("cta_button_label" in marketplace){
            ctaBtnLabel = marketplace.cta_button_label;
        }

        if ("links" in marketplace){
            marketplaceLink = marketplace.links[siteOrigin];
        }

        return {
            marketplaceLink,
            ctaBtnLabel,
        };
    }

    function isPathInRoutingConfig() {
        return pathName in RoutingConfig;
    }

    return {
        redirectUser,
        setMarketplaceLink,
        applyCustomUi,
        getUserOrigin,
        getUserType,
    };
})();