function getParams(query) {
    return new Proxy(new URLSearchParams(query), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
}

function getFromLocalStorage(key) {
    if (!localStorage.getItem(key)) return {};

    return JSON.parse(localStorage.getItem(key));
}

function getPathName() {
    return window.location.pathname;
}

function getCurrentSite() {
    return SITES[window.location.host] || "feedr";
}

function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
}

function getPlaceLongNameByType(addressComponents, type) {
    if (addressComponents) {
        for (let i = 0; i < addressComponents.length; i++) {
            if (addressComponents[i].types.includes(type)) {
                return addressComponents[i].long_name;
            }
        }
    }
    
    return "";
}

function addHiddenInputFieldsToForm(form, fieldsArr) {
    fieldsArr.forEach(field => {
        const targetInput = form.querySelector(`[name="${field}"]`);

        if (targetInput) return;

        const input = document.createElement("input");

        input.type = "hidden";
        input.name = field;

        form.appendChild(input);
    });
}

function assignInputFieldsValues(form, fieldsObj) {
    for (let key in fieldsObj) {
        const value = fieldsObj[key];
        const inputField = form.querySelector(`[name="${key}"]`);

        if (inputField) {
            inputField.value = value;
        }
    }
}

function getDateTimeComponents() {
    const now = new Date();
    const pad = (num, length = 2) => String(num).padStart(length, '0');

    const year = now.getFullYear();
    const month = pad(now.getMonth() + 1);
    const day = pad(now.getDate());

    const hours = pad(now.getHours());
    const minutes = pad(now.getMinutes());
    const seconds = pad(now.getSeconds());
    const milliseconds = pad(now.getMilliseconds(), 3);

    const dateOnly = `${year}-${month}-${day}`;
    const timeOnly = `${hours}:${minutes}`;
    const fullFormat = `${dateOnly}T${timeOnly}:${seconds}.${milliseconds}Z`;

    return {
        dateOnly,
        timeOnly,
        fullFormat
    };
}

function extractLanguageFromPathname() {
    const pathname = window.location.pathname;

    const languagePattern = /^\/([a-z]{2}-[a-z]{2})/i;
    const match = pathname.match(languagePattern);

    return match ? match[1] : "";
}

function singularize(word) {
    if (word.endsWith('ies')) {
        return word.slice(0, -3) + 'y';
    } else if (word.endsWith('s') && !word.endsWith('ss')) {
        return word.slice(0, -1);
    } else {
        return word;
    }
}