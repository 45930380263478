const defaultRedirectLink = "/en-au";
const feedrMarketplaceLink = `https://www.eatfirst.com/en-au/office-catering/vendors`;
const defaultCtaButtonLabel = "Show me amazing food";

const RoutingConfig = {
    "/en-au": {
        existing_user: {
            redirect: {
                links: {
                    feedr: "/en-au/explore",
                    eatfirst: "/en-au/legacy",
                },
            },
        },
        kam_user: {
            redirect: {
                links: {
                    feedr: "/en-au/explore",
                    eatfirst: "/en-au/explore",
                },
            },
        },
        no_kam_user: {
            redirect: {
                links: {
                    feedr: "/en-au/explore",
                    eatfirst: "/en-au/explore",
                },
            },
        }
    },
    all: {
        new_user: {
            marketplace: {
                link: feedrMarketplaceLink,
                cta_button_label: "Show me amazing food",
            },
            ui: [
                {
                    target: '[data-id="customer-portal"]',
                    href: "/en-au/routing",
                },
            ],
        },
        existing_user: {
            marketplace: {
                links: {
                    feedr: feedrMarketplaceLink,
                    eatfirst:
                        "https://catering.eatfirst.com.au/en-AU/suppliers",
                },
            },
            ui: {
                all: [
                    {
                        target: '[data-id="get-started"]',
                        style: "display: none;",
                    },
                ],
                feedr: [
                    {
                        target: '[data-id="customer-portal"]',
                        href: "https://www.eatfirst.com/en-au/authentication/login?destination=/en-au/office-catering/vendors",
                    },
                ],
            },
        },
        kam_user: {
            marketplace: {},
            ui: {},
        }
    },
};
