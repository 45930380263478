document.addEventListener("DOMContentLoaded", (event) => {
    const { 
        setRegularCtaAddressFields, 
        initAddressAutocompeteStreetNumRule, 
        initPhoneNumber, 
        initBookDatePicker, 
        initBookTimepicker, 
        initEventDetailsTimePickers, 
        initOnOccassionSwitched,
        loadZendeskChat,
        updateSummaryFields,
        prefillForm,
        appendIdentityUrlParams,
    } = WebflowCustomCode;

    appendIdentityUrlParams();
    setRegularCtaAddressFields();

    if (typeof disableStreetNumRule === "undefined") {
        initAddressAutocompeteStreetNumRule();
    }

    if (
        window.location.pathname.includes("/l/go") ||
        window.location.pathname.includes("/l/staging/go")
    ) {
        // SegmentEvents.trackQuoteStarted();
        initBookDatePicker();
        initBookTimepicker();
        initEventDetailsTimePickers();
        initOnOccassionSwitched();

        if (typeof urlFormly === "object") {
            updateSummaryFields();
        };

        prefillForm();
    }

    initPhoneNumber();
    loadZendeskChat();
});

const WebflowCustomCode = (function () {
    const errorMap = {
        au: [
            "Invalid number",
            "Invalid country code",
            "Number is too short",
            "Number is too long",
            "Invalid number",
        ],
    };

    const whatIsRequiredChecboxState = {
        food: false,
        staff: false,
    }

    function setRegularCtaAddressFields() {
        const lang = extractLanguageFromPathname();

        setCtaAddressFields(lang);
    }

    function setCtaAddressFields(lang) {
        if (typeof google === "undefined" || !(Array.isArray(autocompleteArr) && autocompleteArr.length > 0) || !(Array.isArray(autocompleteInputArr) && autocompleteInputArr.length > 0)) {
            return;
        }

        const ctaFilterFieldsArr = ["lang", "street", "postcode", "headCount"];

        autocompleteArr.forEach((autocomplete, index) => {
            const form = autocompleteInputArr[index].form;
            addHiddenInputFieldsToForm(form, ctaFilterFieldsArr);

            google.maps.event.addListener(autocomplete, "place_changed", () => {
                const place = autocomplete.getPlace();

                if (place.address_components) {
                    const addressComponents = place.address_components;

                    const ctaFilterFieldsValues = {
                        lang,
                        street: getFullStreetAddress(addressComponents),
                        postcode: getPlaceLongNameByType(addressComponents, "postal_code"),
                        headCount: 0,
                    }

                    assignInputFieldsValues(form, ctaFilterFieldsValues);
                }
            });
        });
    }

    function getFullStreetAddress(addressComponents) {
        const streetNum = getPlaceLongNameByType(addressComponents, "street_number");
        const streetAddress = getPlaceLongNameByType(addressComponents, "route");

        return `${streetNum} ${streetAddress}`;
    }

    function initAddressAutocompeteStreetNumRule() {
        let warningMessage = {
            default: "Oops! Something went wrong while submitting the form.",
            street_num_missing:
                "Please enter your delivery address including street number.",
        };

        autocompleteArr.forEach((autocompleteAddress, index) => {
            autocompleteAddress.addListener("place_changed", () => {
                const place = autocompleteAddress.getPlace();
                const autocompleteForm = autocompleteInputArr[index].closest('[data-cta="autocomplete"]');
                const streetNumber = getStreetNumber(place.address_components);

                if (!streetNumber) {
                    // If the street number is missing, display an error message or handle it as desired
                    editWarningMsg(autocompleteForm, warningMessage["street_num_missing"]);
                    toggleWarning(autocompleteForm, "show");
                } else {
                    editWarningMsg(autocompleteForm, warningMessage["default"]);
                    toggleWarning(autocompleteForm, "hide");
                }
            });
        });

        function getStreetNumber(components) {
            for (let component of components) {
                if (component.types.includes("street_number")) {
                    return component.long_name;
                }
            }
            return null;
        }

        function editWarningMsg(form, msg) {
            form.querySelector(".w-form-fail > div").innerText = msg;
        }

        function toggleWarning(form, action) {
            const failDom = form.querySelector(".w-form-fail");
            const submitBtn = form.querySelector('[type="submit"]');

            if (action == "show") {
                failDom.style.display = "block";
                submitBtn.classList.add("disabled-btn-bg");
                submitBtn.setAttribute("disabled", "true");
            } else {
                failDom.style.display = "none";
                submitBtn.classList.remove("disabled-btn-bg");
                submitBtn.removeAttribute("disabled");
            }
        }
    }

    function eventDetailsEventTimePickers() {
        const timePickers = document.querySelectorAll(
            '[f-form-element="service-timepickers"] div.f-form_layout1_sidebar_item'
        );

        if (timePickers.length !== 2) return;

        const foodServiceTimePicker = timePickers[0];
        const staffServiceTimePicker = timePickers[1];

        const requireFoodCheckbox = document.querySelector(
            '[data-name="What Is Required Food"]'
        );
        const requireStaffFoodCheckbox = document.querySelector(
            '[data-name="What Is Required Event Staffing"]'
        );

        if (!requireFoodCheckbox) return;

        requireFoodCheckbox.addEventListener('change', () => {
            if (requireFoodCheckbox.checked) {
                whatIsRequiredChecboxState.food = true;
                foodServiceTimePicker.style.display = 'block'
                foodServiceTimePicker.querySelector('input[data-toggle$="-end"]').value = '';
            } else {
                whatIsRequiredChecboxState.food = false;
                foodServiceTimePicker.style.display = 'none'
                foodServiceTimePicker.querySelector('input[data-toggle$="-end"]').value = 'NA';
            }

            toggleEventDetailsTimePickers();
            eventDetailsEnableDisableNextBtn();
        });

        if (!requireStaffFoodCheckbox) return;

        requireStaffFoodCheckbox.addEventListener("change", () => {
            if (requireStaffFoodCheckbox.checked) {
                whatIsRequiredChecboxState.staff = true;
                staffServiceTimePicker.style.display = "block";
                staffServiceTimePicker.querySelector('input[data-toggle$="-end"]').value = '';
            } else {
                whatIsRequiredChecboxState.staff = false;
                staffServiceTimePicker.style.display = "none";
                staffServiceTimePicker.querySelector('input[data-toggle$="-end"]').value = 'NA';
            }

            toggleEventDetailsTimePickers();
            eventDetailsEnableDisableNextBtn();
        });
    }

    function toggleEventDetailsTimePickers() {
        const timepickerContainer = document.querySelector(
            '[f-form-element="service-timepickers"]'
        );

        if (!timepickerContainer) {
            console.error(
                'Time picker container is not defined. [f-form-element="service-timepickers"]'
            );
        }

        for (const checkbox in whatIsRequiredChecboxState) {
            if (whatIsRequiredChecboxState[checkbox]) {
                timepickerContainer.style.visibility = "visible";
                return;
            }
        }

        timepickerContainer.style.visibility = "hidden";
    }
    function loadZendeskChat() {
        let head = document.getElementsByTagName('head')[0];
        let zd = document.createElement("script");

        zd.type = "text/javascript";
        zd.id = "ze-snippet"
        zd.src = "https://static.zdassets.com/ekr/snippet.js?key=d979a06f-e006-4d2d-95d5-7a2f63dd6497";

        analytics.ready(function () {
            if (analytics.user().id() === null) {
                head.appendChild(zd);
            }
        });
    }

    function initEventDetailsTimePickers() {
        eventDetailsEventTimePickers();

        const interval = 15;
        const defaultOption = {
            timeFormat: "hh:mm p",
            interval,
            minTime: "6",
            maxTime: "11:45pm",
            disableTouchKeyboard: true,
            startTime: "60:00",
            dynamic: false,
            dropdown: true,
            scrollbar: true,
        };

        //Food timepicker
        $('input[data-toggle="timepicker-foodService-start"]').timepicker({
            ...defaultOption,
            change: function (time) {
                if (!time) return;

                time.setMinutes(time.getMinutes() + interval);

                $('input[data-toggle="timepicker-foodService-end"]').val('');
                $('input[data-toggle="timepicker-foodService-end"]').timepicker(
                    "option",
                    "minTime",
                    time
                );

                disableBtn();
            },
        });

        $('input[data-toggle="timepicker-foodService-end"]').timepicker({
            ...defaultOption,
            change: eventDetailsEnableDisableNextBtn,
        });

        //Staff timepicker
        $('input[data-toggle="timepicker-staffService-start"]').timepicker({
            ...defaultOption,
            minTime: "6",
            change: function (time) {
                if (!time) return;

                time.setMinutes(time.getMinutes() + interval);

                $('input[data-toggle="timepicker-staffService-end"]').val('');
                $('input[data-toggle="timepicker-staffService-end"]').timepicker(
                    "option",
                    "minTime",
                    time
                );

                disableBtn();
            },
        });

        $('input[data-toggle="timepicker-staffService-end"]').timepicker({
            ...defaultOption,
            change: eventDetailsEnableDisableNextBtn,
        });
    }

    function setFoodAndStaffStartTime(time) {
        $('input[data-toggle="timepicker-foodService-start"]').timepicker('setTime', time);
        $('input[data-toggle="timepicker-staffService-start"]').timepicker('setTime', time);
    }

    function initBookDatePicker() {
        const minBookDate = new Date(new Date());
        $('[data-toggle="datepicker"]').datepicker({
            format: "dd.mm.yyyy",
            ignoreReadonly: true,
            autoHide: "true",
            showButtonPanel: true,
            startDate: minBookDate,
        }).attr('readonly', 'readonly');
    }

    function initBookTimepicker() {
        $('[data-toggle="timepicker"]').timepicker({
            timeFormat: "hh:mm p",
            interval: 15,
            minTime: "6",
            maxTime: "11:45pm",
            disableTouchKeyboard: true,
            startTime: "60:00",
            dynamic: false,
            dropdown: true,
            scrollbar: true,
            change: function (time) {
                setFoodAndStaffStartTime && setFoodAndStaffStartTime(time);
                enableBtn && enableBtn();
            },
        }).attr('readonly', 'readonly');;
    }

    function eventDetailsEnableDisableNextBtn() {
        const timePickerInputs = document.querySelectorAll(
            '[f-form-element="service-timepickers"] input[type="text"]'
        );

        for (let i = 0; i < timePickerInputs.length; i++) {
            const timePickerInput = timePickerInputs[i];

            if (isElementVisible(timePickerInput) && timePickerInput.value.trim() == '') {
                disableBtn();
                return;
            }
        }

        enableBtn();
    }

    function isElementVisible(el) {
        if (window.getComputedStyle(el).display === "none") return false;
        if (window.getComputedStyle(el).visibility === "hidden") return false;
        if (window.getComputedStyle(el).opacity === "0") return false;

        const rect = el.getBoundingClientRect();
        if (rect.width === 0 || rect.height === 0) return false;

        const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;
        const windowWidth =
            window.innerWidth || document.documentElement.clientWidth;
        const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
        const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

        return vertInView && horInView;
    }

    function hasNoSelectedEventDetailsCheckboxes() {
        const checkboxes = document.querySelectorAll(
            '[data-form-section="event-details"] input[type="checkbox"]:checked'
        );

        return checkboxes.length == 0;
    }

    function resetEventDetailsInputs() {
        const checkboxes = document.querySelectorAll(
            '[data-form-section="event-details"] input[type="checkbox"]:checked'
        );

        checkboxes.forEach(checkbox => {
            checkbox.parentElement.click();
        });

        const endTimePickers = document.querySelectorAll(
            'input[type="text"][data-toggle$="-end"]'
        );

        endTimePickers.forEach(input => {
            input.value = 'NA'
        })
    }

    function initOnOccassionSwitched() {
        const occassions = document.querySelectorAll(
            "#Christmas_Party, #Corporate-Function-Event"
        );

        occassions.forEach(occassion => {
            occassion.addEventListener('click', () => {
                resetEventDetailsInputs();
            });
        });
    }

    function initPhoneNumber() {
        const phoneInputs = document.querySelectorAll('[data-name="Phone Number"]');

        phoneInputs.forEach((input) => {
            if (!input) return;
            
            setFormPhoneValidationSettings(input);
            createErrorDivIfNotExists(input);
            createValidDivIfNotExists(input);

            const form = input.closest("form");

            var errorMsg = form.querySelector('[data-name="error"]'),
                validMsg = form.querySelector('[data-name="valid"]');

            //Follow ISO 3166-1 alpha-2 codes https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements
            var dataLang = input.dataset.lang;
            var hasErrors = false;

            var iti = window.intlTelInput(input, {
                onlyCountries: ['au', 'nz', 'sg'],
                nationalMode: true,
                initialCountry: "auto",
                allowDropdown: false,
                geoIpLookup: function (success, failure) {
                    if (dataLang) {
                        success(dataLang);
                    } else {
                        success("de");
                    }
                },
                utilsScript:
                    "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.18/js/utils.min.js",
            });

            itiObj = iti;

            var reset = function () {
                input.classList.remove("phone-input_error");
                errorMsg.innerHTML = "";
                errorMsg.classList.add("phone-input_element-hide");
                validMsg.classList.add("phone-input_element-hide");
            };

            // on blur: validate
            input.addEventListener("blur", function () {
                reset();
                if (iti.isValidNumber() || input.value.trim() == "") {
                    hasErrors = false;

                    validMsg.classList.remove("phone-input_element-hide");

                    if (input.value.trim() != "") {
                        //Assign national mode phone number to phone input field
                        input.value = iti.getNumber();
                    }
                } else {
                    hasErrors = true;
                    input.classList.add("phone-input_error");
                    var errorCode = iti.getValidationError();
                    errorCode = errorCode == -99 ? 0 : errorCode;

                    errorMsg.innerHTML = errorMap['au'][errorCode];
                    errorMsg.classList.remove("phone-input_element-hide");
                }
            });

            // on keyup / change flag: reset
            input.addEventListener("change", reset);
            input.addEventListener("keyup", reset);

            //Prevent form to submit if there is error.
            form.querySelector("input[type=submit]").onclick = function (e) {
                if (hasErrors) {
                    e.preventDefault();
                } else {
                    return null;
                }
            };
        });
    }

    function createErrorDiv() {
        const div = document.createElement("div");

        div.classList.add("phone-input_warning");
        div.classList.add("phone-input_element-hide");

        div.dataset.name = "error";
        div.setAttribute("id", "error-msg");

        return div;
    }

    function createPhoneValidDiv() {
        const div = document.createElement("div");

        div.classList.add("phone-input_valid-icon");
        div.classList.add("phone-input_element-hide");
        div.classList.add("w-embed");

        div.dataset.name = "valid";

        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

        svg.setAttribute("width", "100%");
        svg.setAttribute("height", "100%");
        svg.setAttribute("viewBox", "0 0 16 16");
        svg.setAttribute("fill", "none");

        const circle = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "circle"
        );

        circle.setAttribute("cx", "50%");
        circle.setAttribute("cy", "50%");
        circle.setAttribute("r", "50%");
        circle.setAttribute("fill", "#33B497");

        const path = document.createElementNS("http://www.w3.org/2000/svg", "path");

        path.setAttribute(
            "d",
            "M6.92168 10.6673C6.81088 10.6673 6.70931 10.6263 6.6262 10.5341L4.78872 8.49505C4.62251 8.31061 4.62251 8.02371 4.78872 7.83927C4.95492 7.65483 5.21346 7.65483 5.37967 7.83927L6.93091 9.55044L10.6151 5.47231C10.7813 5.28787 11.0399 5.28787 11.2061 5.47231C11.3723 5.65675 11.3723 5.94365 11.2061 6.12809L7.22639 10.5341C7.13405 10.6263 7.03248 10.6673 6.92168 10.6673Z"
        );
        path.setAttribute("fill", "white");

        svg.appendChild(circle);
        svg.appendChild(path);
        div.appendChild(svg);

        return div;
    }

    function createErrorDivIfNotExists(phoneInput) {
        if (!phoneInput.closest("form").querySelector('[data-name="error"]')) {
            phoneInput.parentNode.appendChild(createErrorDiv());
        }
    }

    function createValidDivIfNotExists(phoneInput) {
        if (!phoneInput.closest("form").querySelector('[data-name="valid"]')) {
            phoneInput.parentNode.appendChild(createPhoneValidDiv());
        }
    }

    function setFormPhoneValidationSettings(phoneInput) {
        phoneInput.closest("form").dataset.settings = "phone-validation";
    }

    function updateSummaryFields() {
        const nextBtns = document.querySelectorAll(
            '[data-form="next-btn"], #summary-page'
        );

        if (!nextBtns) return false;

        const fieldsToSummary = [
            "Address",
            "Time",
            "How Many Guests Do You Need To Feed",
            "Budget per person",
            "Budget total",
        ];

        nextBtns.forEach(nextBtn => {
            nextBtn.addEventListener("click", () => {
                fieldsToSummary.forEach((field) => {
                    const summaryFieldContainer = document.querySelector(
                        `[data-input-field="${field}"]`
                    );
                    summaryFieldContainer.innerText = document.querySelector(
                        `input[data-name="${field}"]`
                    ).value;

                    summaryFieldContainer.style.display = "block";
                });

                hideShowBudgetSummary();
            });
        });

    }

    function hideShowBudgetSummary() {
        const budgetPerPerson = document.querySelector("#budget-per-person");
        const budgetTotal = document.querySelector("#budget-total");
        const budgetRadioInput = document.querySelector('input[name="Budget"]:checked');

        const value = budgetRadioInput.value;

        if (value == "per Person") {
            budgetPerPerson.style.display = "block";
            budgetTotal.style.display = "none";
        } else {
            budgetPerPerson.style.display = "none";
            budgetTotal.style.display = "block";
        }
    }

    function prefillForm() {
        const params = getParams(window.location.search);

        if (typeof params != "undefined" && 'data' in params && params.data) {
            setTimeout(() => {
                const b64decoded = atob(params.data);
                const data = getParams(b64decoded);
                const fields = document.querySelectorAll(".field");

                fields.forEach((f) => {
                    if (data[f.id]) {
                        let value = data[f.id];
                        f.value = value.trim();
                    }
                });
            }, 500);
        }
    }

    function appendIdentityUrlParams() {
        const ajsUid = getFromLocalStorage('ajs_user_id');
        const ajsAnonymousId = getFromLocalStorage('ajs_anonymous_id');
        
        if (!ajsUid && !ajsAnonymousId) return;

        const segmentParams = {
            ajs_uid: ajsUid || "",
            ajs_aid: ajsAnonymousId || "",
        };

        const targetElem = document.querySelectorAll('a, [data-cta="autocomplete"] form');
        const domain = window.location.hostname;
        
        targetElem.forEach(elem => {
            let urlProperty = elem.href ? 'href' : elem.action ? 'action' : null;
            if (!urlProperty || !elem[urlProperty].includes('https://')) return;
            
            const url = new URL(elem[urlProperty]);
            const linkDomain = url.hostname;
            
            if ((domain.endsWith('webflow.io') && linkDomain.endsWith('eatfirst.com')) ||
                (domain.endsWith('webflow.io') && linkDomain.endsWith('eatfirst.com.au')) ||
                ((domain.endsWith('eatfirst.com') && linkDomain.endsWith('eatfirst.com.au')) ||
                (domain.endsWith('eatfirst.com.au') && linkDomain.endsWith('eatfirst.com')))) {

                if (urlProperty === 'href') {
                    for (let key in segmentParams) {
                        url.searchParams.set(key, segmentParams[key]);
                    }

                    elem.href = url.toString();
                } else if (urlProperty === 'action') {
                    addHiddenInputFieldsToForm(elem, Object.keys(segmentParams));
                    assignInputFieldsValues(elem, segmentParams);
                }
            }
        });
    }

    return {
        setRegularCtaAddressFields,
        initAddressAutocompeteStreetNumRule,
        initPhoneNumber,
        initBookDatePicker,
        initBookTimepicker,
        initEventDetailsTimePickers,
        initOnOccassionSwitched,
        loadZendeskChat,
        updateSummaryFields,
        prefillForm,
        appendIdentityUrlParams,
    }
})();